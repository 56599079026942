



























































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { getTagList, TAG_ALL } from '@/common/couponUtils';
import { CouponsRepository } from '@/repositories/CouponsRepository';
import { CouponInteraction } from '@/controller/CouponInteraction';
import { CouponItem } from '@/models/CouponItem';
import CouponDialog from '@/components/CouponDialog.vue';
import CouponCard from '@/components/CouponCard.vue';
import ContactPanel from '@/components/ContactPanel.vue';

@Component({
  components: {
    CouponDialog,
    'coupon-card': CouponCard,
    ContactPanel
  }
})
export default class P0196 extends Vue {
  clickedCoupon: any = null;
  isCouponDialogOpen = false; // クーポンクリック時のv-dialogをコントロールする
  isFilterDialogOpen = false; // フィルタークリック時のv-dialogをコントロールする
  autoDialogOpenIdDone = false;

  currentTag = TAG_ALL; // 選択中のクーポンタグ

  coupons: CouponItem[] = [];

  //スクロールイベントのフラグ（処理を軽くするためのもの）
  isFlagScroll = false;
  //絞り込みボタン表示フラグ
  isDisplayFixSideBtn = false;

  //クーポン一覧がブラウザ上に表示された時のみ、絞り込みボタンを表示するように判定
  judgeDisplayFixBtn() {
    if (this.isFlagScroll) {
      return;
    }

    this.isFlagScroll = true;

    //スクロールイベントの発生回数を減らす
    window.requestAnimationFrame(() => {
      //クーポン一覧DOM
      let couponListElem = document.getElementById('CouponList');
      //クーポン一覧の上端
      let couponTop = 0;
      //クーポン一覧の下端
      let couponBottom = 0;
      if (couponListElem instanceof HTMLElement) {
        couponTop =
          window.pageYOffset + couponListElem.getBoundingClientRect().top;
        couponBottom =
          window.pageYOffset +
          couponListElem.getBoundingClientRect().top +
          couponListElem.getBoundingClientRect().height;
      }

      if (
        window.innerHeight + window.scrollY > couponTop &&
        window.scrollY < couponBottom
      ) {
        this.isDisplayFixSideBtn = true;
      } else {
        this.isDisplayFixSideBtn = false;
      }

      this.isFlagScroll = false;
    });
  }

  //スクロールイベント追加
  mounted() {
    window.addEventListener('scroll', this.judgeDisplayFixBtn);
  }

  //dom構築後、描画が完了したタイミングで実施
  updated() {
    this.judgeDisplayFixBtn();
  }

  destroyed() {
    window.removeEventListener('scroll', this.judgeDisplayFixBtn);
  }

  get interaction() {
    return CouponInteraction.create({
      repository: new CouponsRepository(
        this.$store.state.isSP,
        this.$store.state.supportsWebP
      )
    });
  }

  get queryCouponID() {
    const query = this.$route.query || {};
    if (!query.couponID) {
      return null;
    } else if (Array.isArray(query.couponID)) {
      return null;
    } else {
      return query.couponID;
    }
  }

  /**
   * クーポンは認証済みの場合にクーポンコードを表示する必要があるため、createdではなく$auth.loadingの変更をトリガーにmicroCMSから取得する。
   */
  @Watch('$auth.loading', { immediate: true })
  initialize() {
    if (this.$auth.loading) {
      return;
    }

    this.handleUpdateAsync(async () =>
      this.interaction.initialize({
        withCode: this.$auth.isAuthenticated
      })
    );
  }

  /**
   * クエリパラメータにcouponIDがある場合、初回訪問時に自動でダイアログ表示させる。
   * microCMSからcouponsを取得してきたことをトリガーに実行する。
   */
  @Watch('coupons')
  autoOpenCouponDialog() {
    // クエリパラメータに値がなければ、そのあとは起動しなくて良い
    if (!this.queryCouponID) {
      return;
    }

    // 一度起動したらその後は起動させない。
    if (this.autoDialogOpenIdDone) {
      return;
    }

    const highlight = this.interaction.entireList.find(
      e => e.id === this.queryCouponID
    );
    highlight && this.openCouponDialog(highlight);

    this.autoDialogOpenIdDone = true; // 次回から自動ダイアログ表示させない
  }

  openCouponDialog(c: any) {
    this.clickedCoupon = c;
    this.isCouponDialogOpen = true;
    this.$dataLayer.push({
      coupon_title: c.coupon_name,
      event: 'coupon-click'
    });
  }

  get tagList() {
    return getTagList();
  }

  onClickTag(name: string) {
    this.isFilterDialogOpen = false;
    this.handleUpdate(() => this.interaction.selectTag(name));

    //クーポン一覧DOM
    let couponListElem = document.getElementById('CouponList');
    //クーポン一覧の上端
    let couponTop = 0;
    if (couponListElem instanceof HTMLElement) {
      couponTop =
        window.pageYOffset + couponListElem.getBoundingClientRect().top;
    }

    window.scrollTo({ top: couponTop, behavior: 'smooth' });
  }

  onClickSeeMoreCoupons() {
    this.handleUpdate(() => this.interaction.seeMorePage());
  }

  disabledSeeMoreCoupon() {
    return this.interaction.hasNotMorePage;
  }

  handleUpdate(command: () => void) {
    command();
    // リアクティブ検知のため、更新の都度再代入
    this.coupons = this.interaction.availableList;
    this.currentTag = this.interaction.currentTag;
  }

  handleUpdateAsync(command: () => Promise<void>) {
    command()
      .then(() => {
        // リアクティブ検知のため、更新の都度再代入
        this.coupons = this.interaction.availableList;
        this.currentTag = this.interaction.currentTag;
      })
      .catch(() => {});
  }
}
