import { CouponsRepository } from '@/repositories/CouponsRepository';
import { ICouponsRepository } from '@/repositories/interface/ICouponsRepository';
import { TAG_ALL } from '@/common/couponUtils';

export interface CouponInteractionOption {
  withCode: boolean;
}

export class CouponInteraction {
  private list = CouponsRepository.empty(); // サーバから取得した元のリスト
  private filteredList = CouponsRepository.empty(); // 常にタグでfilterしたリストを保持（再計算を防ぐ）
  private MAX_VISIBLE_CNT = 10;
  private offset = this.MAX_VISIBLE_CNT;
  private tag = TAG_ALL;

  static create({
    repository
  }: {
    repository: ICouponsRepository;
  }): CouponInteraction {
    return new CouponInteraction(repository);
  }

  private constructor(private readonly repository: ICouponsRepository) {}

  /**
   * @param option クーポンコードありの場合はwithCode: trueで呼び出すこと。デフォルトfalse。 allianceIdを指定するとその提携先のクーポンに絞り込む。
   */
  async initialize(option: CouponInteractionOption = { withCode: false }) {
    if (option.withCode) {
      this.list = await this.repository.get();
    } else {
      this.list = await this.repository.getWithoutCode();
    }

    this.filteredList = this.calcFilteredList();
  }

  get entireList() {
    return this.list;
  }

  get availableList() {
    return this.filteredList.slice(0, this.offset);
  }

  private calcFilteredList() {
    return this.tagIsAll
      ? this.list
      : this.list.filter(c => c.coupon_tag === this.tag);
  }

  private get tagIsAll() {
    return this.tag === TAG_ALL;
  }

  private get currentOffset() {
    return this.offset;
  }

  private get offsetLimit() {
    return this.filteredList.length;
  }

  get currentTag() {
    return this.tag;
  }

  get hasNotMorePage() {
    return this.offsetLimit <= this.currentOffset;
  }

  selectTag(tag: string) {
    if (this.tag === tag) {
      return;
    }
    this.tag = tag;
    this.offset = this.MAX_VISIBLE_CNT;
    this.filteredList = this.calcFilteredList();
  }

  seeMorePage() {
    const nextOffset = Math.min(
      this.list.length,
      this.offset + this.MAX_VISIBLE_CNT
    );
    this.offset = nextOffset;
  }
}
